import React from 'react';
import Header from '../components/Header';
import banner from '../images/orignal/webp/Hafiz-1.webp';
import { IoRadioButtonOn } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { FaAmbulance } from "react-icons/fa";

const HowItWorks = () => {
  return (
    <>
      <Header
        // bannerHeight='60vh'
        backgroundImage={banner}
        bannerHeading="How It Works"
        bannerText="Learn how our service helps you stay connected and safe with easy-to-use technology and reliable support."
      />
      <div className="bg-gray-100 py-16 font-akkurat">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-[#fc0829] mb-6">How It Works</h2>
          <p className="text-gray-600 mb-12">
            Discover how MedAlert works to keep you and your loved ones safe.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
            {/* Step 1 */}
            <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg  shadow-lg">
              <div className="text-[#f83737] text-5xl mb-4"><IoRadioButtonOn/></div> {/* Icon for the button press */}
              <h3 className="text-lg text-gray-800 font-semibold mb-2">Step 1: Press Button</h3>
              <p className="text-gray-600">
                In case of emergency, simply press the button on your MedAlert device to initiate a response.
              </p>
            </div>

            {/* Step 2 */}
            <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg shadow-lg">
              <div className="text-[#f83737]  text-5xl mb-4"><FaPhoneAlt/></div> {/* Icon for immediate assistance */}
              <h3 className="text-lg text-gray-800 font-semibold mb-2">Step 2: Immediate Assistance</h3>
              <p className="text-gray-600">
                As soon as the button is pressed, MedAlert instantly contacts emergency services and your designated contacts.
              </p>
            </div>

            {/* Step 3 */}
            <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg shadow-lg">
              <div className="text-[#f83737] text-5xl mb-4"><FaAmbulance/></div> {/* Icon for help coming */}
              <h3 className="text-lg text-gray-800 font-semibold mb-2">Step 3: Help Comes to You</h3>
              <p className="text-gray-600">
                Help is on the way. MedAlert ensures that the right assistance reaches you quickly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
