import React from 'react';
import { useNavigate } from 'react-router-dom';
// import Header from '../components/Header';
// import banner from '../images/orignal/webp/Hafiz-1.webp'

const ThankYouPage = () => {
  const navigate = useNavigate();

  return (
<>
{/* <Header
bannerHeight='37vh'
backgroundImage={banner}
/> */}

    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-16 rounded-lg shadow-lg w-full max-w-lg text-center">
        <h1 className="text-3xl font-semibold text-[#fc0829] mb-4">Thank You!</h1>
        <p className="text-gray-700 mb-6">
          {/* Your order has been placed successfully. We appreciate your business and will be in touch soon with your delivery details. */}
          We have recieved your order. Our agent will be in contact with you wihin 24 hours.
        </p>
        
        {/* <div className="mb-6">
          <h2 className="text-lg font-medium text-gray-800">Order Summary</h2>
          <ul className="mt-2 space-y-2 text-left">
            <li className="flex justify-between">
              <span>Product 1</span>
              <span>$25.00</span>
            </li>
            <li className="flex justify-between">
              <span>Product 2</span>
              <span>$15.00</span>
            </li>
            <li className="flex justify-between font-semibold">
              <span>Total</span>
              <span>$40.00</span>
            </li>
          </ul>
        </div> */}

        <button
          className="px-6 py-3 bg-[#EE344E] text-white text-[16px] leading-[24px] font-[400] rounded-3xl hover:bg-[#fc0829] transition"
          onClick={() => navigate('/')}
        >
          Back to Home
        </button>
      </div>
    </div>

    </>
  );
};

export default ThankYouPage;
