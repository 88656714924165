import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../context/AppContext";
import { TrashIcon } from "@heroicons/react/24/outline";
import { FaLongArrowAltLeft } from "react-icons/fa";
import logo from '../images/logo/PNG.png';
import { useNavigate } from 'react-router-dom';

function Checkout() {
  const { cartItems, removeFromCart } = useCart();
  const subtotal = cartItems.reduce((total, item) => total + item.price, 0).toFixed(2);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
  email: '',
  phone: '',
  address: '',
  zip_code: '',
  message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForm1Submit = (e) => {
    e.preventDefault();
    console.log('Form 1 data saved:', { name: formData.name, phone: formData.phone, zip_code: formData.zip_code  });
    setStep(2);
    // Reset form data to clear inputs
    setFormData({
      name: '',
      email: '',
      phone: '',
      address: '',
      zip_code: '',
      message: ''
     
  });
  };

  const handleForm2Submit = (e) => {
    e.preventDefault();
    console.log('Form 2 data saved:', { address: formData.address, email: formData.email, message: formData.message });
    // alert('Data successfully saved!');
    // Reset form data to clear inputs
    setFormData({
      name: '',
      email: '',
      phone: '',
      address: '',
      zip_code: '',
      message: ''
      
  });
  navigate('/thank-you');
  };

  return (
    <>
      <header className=" max-w-[1440px] w-full mx-auto px-5 py-4">
        <div className="absolute top-4 w-[93%] flex items-center justify-between">
          <Link to="/" className="w-[150px] h-[50px] text-3xl font-[400] leading-24">
            <img src={logo} alt="Logo" className="w-full h-full" />
          </Link>
        </div>
      </header>

      <div className=" max-w-[1440px] w-full mx-auto p-8 pt-20 flex justify-between">
        {cartItems.length === 0 ? (
          <div className="w-[100%] flex flex-col gap-8 justify-center items-center text-[#5D5C5B] h-80 font-akkurat">
            <p className="text-center text-[28px]  md:text-[40px] leading-[60px] font-light">
              You have no items in your cart.
            </p>
            <Link
              to="/"
              className="px-10 md:px-20 py-2 text-xl bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829]"
            >
              Shop Now
            </Link>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row w-full md:space-x-10">
            {/* Left side: Cart items */}
            <ul className="w-full md:w-1/2 space-y-4 text-[#5D5C5B]">
              <Link
                to="/"
                className="flex items-center gap-3 text-xl font-normal mb-4"
              >
                <FaLongArrowAltLeft /> Continue Shopping
              </Link>
              <h1 className="text-5xl font-[400] font-akkurat mb-4">
                Review Cart
              </h1>
              {cartItems.map((item) => (
                <li
                  key={item.id}
                  className="border pr-2 rounded flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <div>
                      <div className="flex gap-2">
                      <img src={item.image} alt=" no"className="w-20 h-20"/>
                      <div>
                      <h2 className="text-xl">{item.name}</h2>
                     
                      <p className="text-gray-500">${item.price.toFixed(2)}</p>
                      </div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="flex items-center text-red-500 hover:text-red-700"
                  >
                    <TrashIcon className="h-5 w-5 mr-1" />
                    Remove
                  </button>
                </li>
              ))}
              <div className="flex justify-between items-center border-t pt-4 mt-4">
                <h2 className="text-2xl font-semibold">Subtotal</h2>
                <p className="text-2xl font-semibold">${subtotal}</p>
              </div>
            </ul>

            {/* Right side: Form */}
            <div className="w-full md:w-1/2 bg-white font-akkurat shadow-md rounded-lg p-8 md:mt-0 mt-5 md:space-y-4">
              {step === 1 && (
                <form onSubmit={handleForm1Submit}>
                  <h2 className="text-2xl font-bold text-center mb-4">Step 1</h2>
                  <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg  bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Phone</label>
                    <input
                      type="text"
                      placeholder="Enter Phone #"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg  bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Zip Code</label>
                    <input
                      type="number"
                      placeholder="Enter Zip-Code"
                      name="zip_code"
                      value={formData.zip_code}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                      required
                    />
                  </div>
                
               
                  <button
                    type="submit"
                    className="bg-[#EE344E] hover:bg-[#fc0829] text-white font-bold py-2 px-10 rounded-full"
                  >
                    Next
                  </button>
                </form>
              )}

              {step === 2 && (
                <form onSubmit={handleForm2Submit}>
                  <div className="flex items-center mb-4">
                    <button 
                      type="button" 
                      onClick={() => setStep(1)}
                      className=" hover:text-[#fc0829] flex items-center gap-2 font-semibold"
                    >
                      <FaLongArrowAltLeft /> Go Back
                    </button>
                  </div>
                  <h2 className="text-2xl font-bold text-center mb-4">Step 2</h2>
                  <div className="mb-4">
                    <label className="block text-gray-700">Address</label>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Email</label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg  bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Message</label>
                    <textarea
                      type="text"
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-[#EE344E] hover:bg-[#fc0829] text-white font-bold py-2 px-10 rounded-full"
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Checkout;
