"use client";

import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoChevronForward } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom';

import "../style/style.css";
import logo from '../images/logo/PNG.png';

export default function Header({ backgroundImage, bannerHeading, bannerSpan, bannerText, bannerButton, buttonLink, bannerHeight }) {
  const location = useLocation(); // Get the current path

  // Set button visibility. Default is hidden, shown on specific paths.
  const shouldShowButton = location.pathname === "/";// Add more conditions for specific pages

  // Set text alignment dynamically based on the current page path
  const textAlign = location.pathname === "/" ? "left" : "center";


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSection, setOpenSection] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
 


  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleSection = (section) => setOpenSection(openSection === section ? null : section);

  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);

  return (
    <>

      <div className={`
    ${window.location.pathname === '/' ? "main-home-header" : "main-other-pages"
        }`}
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', height: bannerHeight }}>
        <header className="sticky top-0 px-6 py-4">
          <nav className="flex items-center justify-between backdrop-blur-md bg-white/30 shadow-lg px-4 py-2 rounded-3xl">
            <Link to='/' className="w-[150px] h-[50px]">
              <img src={logo} alt="logo" className="w-full h-full" />
            </Link>
            <div className="hidden desktop:flex items-center space-x-10 text-xl text-white text-[16px] leading-[24px] font-[400] font-akkurat">
              <Link to="/why-ringer" className="hover:text-[#fc0829]">Why Us</Link>
              <a href="/how-it-work" className="hover:text-[#fc0829]">How It Works</a>

              <div
                className="relative group"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Link to="/products" className="flex py-3 gap-1 items-center justify-center hover:text-[#fc0829]">Products <IoIosArrowDown/></Link>

                {/* Main Product Dropdown */}
                {isDropdownOpen && (
                 <div
                 className="absolute -left-[20rem] mt-1 w-[45rem] bg-white shadow-lg rounded-md z-[50] 
                            grid grid-cols-2 gap-2 py-2 px-2 animate-slideDown"
                 style={{
                   animation: "slideDown 0.4s ease-out forwards",
                 }}
               >
                    {/* Column 1: On the Go */}
                    <div className="flex flex-col gap-2 on-the-go text-black p-5">
                      <h3 className="py-2 font-semibold text-[24px]">On-the-Go</h3>
                      <p className="mb-3">Get coverage wherever you go. Find a system that’s as mobile as you are.</p>
                      <Link to="/category/ringer-watch" className="hover:text-[#fc0829] block py-1 text-black">Ringer Watch</Link>
                      <Link to="/category/ringer-push-button" className="hover:text-[#fc0829] block py-1 text-black">Ringer Push Button</Link>
                      <Link to="/category/pendant" className="hover:text-[#fc0829] block py-1 text-black">Pendant</Link>
                    </div>

                    {/* Column 2: At Home */}
                    <div className="flex flex-col gap-2 at-home text-black p-5">
                      <h3 className="py-2 font-semibold text-[24px]">At Home</h3>
                      <p className="mb-3">Coverage for you where you are the most. Keep it simple & affordable.</p>
                      <Link to="/category/cellular-home" className="hover:text-[#fc0829] block py-1 text-black">At Home Cellular</Link>
                      <Link to="/category/landline" className="hover:text-[#fc0829] block py-1 text-black">At Home Landline</Link>
                    </div>

                    {/* Optional Third Column */}
                    {/* Uncomment to use */}
                    {/* <div className="flex flex-col gap-2">
        <h3 className="px-4 py-2 font-semibold text-black">Free Advice</h3>
        <Link to="/category/advice-1" className="hover:text-[#fc0829] block px-4 py-2 text-black hover:bg-gray-200">Advice 1</Link>
        <Link to="/category/advice-2" className="hover:text-[#fc0829] block px-4 py-2 text-black hover:bg-gray-200">Advice 2</Link>
        <Link to="/category/advice-3" className="hover:text-[#fc0829] block px-4 py-2 text-black hover:bg-gray-200">Advice 3</Link>
      </div> */}
                  </div>
                )}
              </div>




              <a href="/faq's" className="hover:text-[#fc0829]">FAQ's</a>
              <Link to='/contact' className="hover:text-[#fc0829]">Contact</Link>
              <Link to="/free-advise">
                <button className="hidden tab:flex px-6 py-3 bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829]">Free Advise</button>
              </Link>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-white hidden tab:flex font-akkurat">Menu</span>
              <button
                onClick={toggleMenu}
                className="text-white hover:text-[#fc0829] border border-white rounded-full p-3"
                aria-expanded={isMenuOpen}
              >
                {isMenuOpen ? <IoMdClose className="h-6 w-6" /> : <FiMenu className="h-6 w-6" />}
              </button>
            </div>
          </nav>
        </header>


        <main className="px-6 py-8 md:py-12 w-full font-akkurat">
          <div
            className={` text-white  ${window.location.pathname === '/' ? "text-center md:text-left md:max-w-[47%] w-full" : "text-center md:max-w-[100%] md:w-[50%] w-[95%] mx-auto"
              } md:pl-5`}
          // style={{ textAlign: textAlign }}
          >
            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, delay: 0.6 }}
              className="bigdesktop:mt-20 bigdesktop:ml-10 bigdesktop:text-[60px]  text-3xl md:text-[52px] font-[400] leading-[40px] md:leading-[64px] mb-4"
            >
              {bannerHeading}
              <span className="font-editorial italic"> {bannerSpan}</span>
            </motion.h1>
            <div className={`
           ${window.location.pathname === '/' ? "bigdesktop:mt-48  md:mt-24 mt-20" : "md:mt-10 mt-5"
              }`}
            >
              <motion.p
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3, delay: 0.5, ease: "easeInOut" }}
                className={`bigdesktop:text-[24px] bigdesktop:ml-10 md:text-[20px] leading-[27px] font-[400] text-md mb-8 w-full
              ${window.location.pathname === '/' ? "text-center md:text-left md:max-w-[65%] w-[100%]" : "text-center md:max-w-[100%] w-[70%] mx-auto"
                  } `}
              >
                {bannerText}
              </motion.p>
              {shouldShowButton && (
                <div>
                  <Link to={buttonLink}>
                    <motion.button
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      transition={{ duration: 0.3, delay: 0.4, ease: "easeInOut" }}
                      className="px-6 py-3 bg-[#EE344E] text-white bigdesktop:ml-10 bigdesktop:text-[18px] text-[16px] leading-[24px] font-[400] rounded-3xl hover:bg-[#fc0829]"
                    >
                      {bannerButton}
                    </motion.button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>



      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="fixed z-[999] top-0 right-0 w-full h-screen bg-white text-black shadow-lg"
          >
            <div className="py-4 px-10 relative">
              <Link to='/'>
                <div className="w-[150px] h-[50px] text-3xl font-bold">
                  <img src={logo} alt="" className="w-full h-full" />
                </div>
              </Link>
              <button
                onClick={toggleMenu}
                className="absolute top-6 right-12 hover:text-[#fc0829] border border-black rounded-full p-3"
                aria-label="Close menu"
              >
                <IoMdClose className="h-6 w-6" />
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-3 p-4 md:px-10">
              {[
                {
                  title: "Quick Links", links: [{ name: "Why Ringer", path: "/why-ringer" },
                  { name: "How It Works", path: "/how-it-work" }]
                },
                {
                  title: "Products", links: [
                    { name: "Ringer Watch", path: "/category/ringer-watch" },
                    { name: "Ringer-push button", path: "/category/ringer-push-button" },
                    { name: "Pendant", path: "/category/pendant" },
                    { name: "At Home Cellular", path: "/category/cellular-home" },
                    { name: "At Home Landline", path: "/category/landline" }
                  ]
                },
                {
                  title: "Legal", links: [
                    { name: "Terms & Conditions", path: "/policy" },
                    { name: "Privacy Policy", path: "/policy" },
                    { name: "Return Policy", path: "/return-policy" }
                  ]
                },
                {
                  title: "Support", links: [
                    { name: "Contact Us", path: "/contact" },
                    { name: "FAQs", path: "/faq's" },
                    { name: "Free Advice", path: "/free-advise" }]
                },
              ].map((section, index) => (
                <div key={index} className="md:border-0 border-t border-gray-400 pt-4 md:pt-0">
                  <h3
                    onClick={() => toggleSection(section.title)}
                    className="font-semibold text-xl cursor-pointer flex justify-between items-center text-[#5D5C5B]"
                  >
                    {section.title}
                    <span className="text-lg md:hidden text-[#5D5C5B]">
                      {openSection === section.title ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </span>
                  </h3>
                  <AnimatePresence>
                    {(openSection === section.title || window.innerWidth >= 768) && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className="flex flex-col md:space-y-2"
                      >
                        {section.links.map((link, linkIndex) => (
                          <motion.li
                            key={linkIndex}
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: linkIndex * 0.1 }}
                          >
                            {typeof link === "object" ? (
                              <a href={link.path} className="block py-2 hover:text-[#fc0829]">
                                {link.name}
                              </a>
                            ) : (
                              <a href={`./${link.replace(/\s+/g, '').toLowerCase()}`} className="block py-2 hover:text-[#fc0829]">
                                {link}
                              </a>
                            )}
                          </motion.li>
                        ))}
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>


  );
}
