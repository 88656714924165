import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // Import AnimatePresence

const MainSection = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false); // State to track visibility

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true); // Show animation on entering view
        } else {
          setIsVisible(false); // Trigger exit animation on exit
        }
      },
      { threshold: 0.4 } // Trigger when 80% is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  return (
    <>
      <div
        ref={sectionRef} // Attach the ref here
        className="h-[50vh] md:min-h-[70vh] flex justify-center items-center bg-[#F5F5F7] font-akkurat"
      >
        <AnimatePresence>
          {isVisible && (
            <motion.div
              className="w-[90%] md:w-[45%] flex flex-col justify-center items-center gap-5"
              initial={{ opacity: 0, y: 100 }} // Initial state
              animate={{ opacity: 1, y: 0 }} // Animate on mount
              exit={{ opacity: 0, y: -50 }} // Animate out on exit
              transition={{ duration: 0.2 }} // Duration of the animation
            >
              <h1 className="md:text-7xl text-4xl text-center font-[250] bg-gradient-to-r from-[#8f0317] to-[#f52a46] bg-clip-text text-transparent">
                Compact, Smart, 
                <span className="italic md:text-6xl text-3xl"> and Easy to Use</span>
              </h1>
              <p className="text-center text-[18px] font-[400] leading-[24px] text-[#5D5C5B] md:text-md">
                Our MedAlert devices are designed with simplicity and effectiveness in mind, ensuring you have access to assistance whenever you need it. Explore our range of devices suited for both on-the-go and at-home use.
              </p>
              <Link to='/products' className="px-6 py-3 bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829]">
                Find the Right Device for You
              </Link>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default MainSection;
