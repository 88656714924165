import React from "react";
// import image from '../images/footer/business-footer-cta.webp'
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaPinterest,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "../style/style.css";
import { Link } from "react-router-dom";
import logo from '../images/logo/White.png';

const Footer = () => {
  return (

    <div className="bg-gray-800 text-white py-10 px-5 md:px-15 lg:px-10 flex flex-col lg:flex-row gap-10 font-akkurat">
      {/* Left Section */}
      <div className="lg:w-[25%] flex flex-col items-start h-full">
        {/* Left Side: Logo */}
        <div className="w-[250px] h-[80px] mx-auto mb-4">
          <Link to="/" className="w-full h-full">
            <img src={logo} alt="Logo" className="w-full h-full" />
          </Link>
        </div>

        {/* left Side: Image with Text Overlay */}
        <div className="w-full h-full flex relative footer-image">
          {/* Text Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col items-center lg:items-start p-8 gap-20 md:gap-48">
            <h2 className="text-[32px] font-[400] leading-[32px] text-white">
              Invest in the health
              <div className="italic text-[28px] font-[400] leading-[32px]">of your organization</div>
            </h2>
            <Link to='/products' className="px-10 py-3 text-[16px] font-[400] leading-[24px] bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829]">
              Explore
            </Link>
          </div>
        </div>
      </div>
      {/* Right Section */}
      <div className="lg:w-[75%] flex flex-col gap-8 mt-10 lg:mt-0">
        {/* Top Columns */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 md:grid-cols-3 gap-8">
          {/* Column 1 */}
          <div>
            <h3 className="font-bold text-[20px] leading-[24px] mb-4 text-white">Products</h3>
            <ul className="space-y-2">
              <li>
                <Link to='/category/ringer-watch' className="hover:underline">
                  Ringer Watch
                </Link>
              </li>
              <li>
                <Link to='/category/ringer-push-button' className="hover:underline">
                  Ringer-push Button
                </Link>
              </li>
              <li>
                <Link to='/category/pendant' className="hover:underline">
                  Pendant
                </Link>
              </li>
              <li>
                <Link to='/category/cellular-home' className="hover:underline">
                  At Home Cellular
                </Link>
              </li>
              <li>
                <Link to='/category/landline' className="hover:underline">
                  At Home Landline
                </Link>
              </li>
            </ul>
          </div>

          {/* Column 2 */}
          <div>
            <h3 className="font-bold text-[20px] leading-[24px] mb-4 text-white">Support</h3>
            <ul className="space-y-2">
              <li>
                <a href="/contact" className="hover:underline">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/faq's" className="hover:underline">
                  FAQ's
                </a>
              </li>
              <li>
                <a href="/free-advise" className="hover:underline">
                  Free Advise
                </a>
              </li>
              <li>
                {/* <a href="#" className="hover:underline">
                  My Account
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Contact
                </a> */}
              </li>
            </ul>
          </div>

          {/* Column 3 */}
          <div className="flex gap-24 minimobile:gap-32 minitab:gap-40 md:gap-0 md:flex-col">
            <div className="flex flex-col">
              <h3 className="font-bold text-[20px] leading-[24px] mb-4 text-white">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:text-gray-300">
                    Terms & Condition
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-gray-300">
                    Policy
                  </a>
                </li>
                <li>
                  <a href="/return-policy" className="hover:text-gray-300">
                    Return Policy
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="">
              <h3 className="font-bold text-[20px] leading-[24px] mt-6 mb-4 text-white">Blog</h3>
              <ul>
                <li>
                  <a href="#" className="hover:text-gray-300">
                    The Pulse
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        {/* Bottom Section with Social Links and Email Subscription */}
        <div className="max-w-[700px] w-full flex flex-col-reverse md:flex-row justify-between items-left space-y-5 md:space-y-0 text-black font-poppins">
          {/* Social Icons */}
          <div className="">
            <h3 className="font-semibold text-[20px] mb-4 text-white">Social</h3>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-xl bg-white hover:bg-[#ee344e] p-2 rounded-full"
              >
                <FaFacebook />
              </a>
              <a
                href="#"
                className="text-xl bg-white hover:bg-[#ee344e] p-2 rounded-full"
              >
                <FaInstagram />
              </a>
              <a
                href="#"
                className="text-xl bg-white hover:bg-[#ee344e] p-2 rounded-full"
              >
                <FaXTwitter />
              </a>
              <a
                href="#"
                className="text-xl bg-white hover:bg-[#ee344e] p-2 rounded-full"
              >
                <FaYoutube />
              </a>
              {/* <a
                href="#"
                className="text-xl bg-white hover:bg-[#ee344e] p-2 rounded-full "
              >
                <FaTiktok />
              </a>
              <a
                href="#"
                className="text-xl bg-white hover:bg-[#ee344e] p-2 rounded-full "
              >
                <FaPinterest />
              </a> */}
            </div>
          </div>

          {/* Email Subscription */}
          <div className="">
            <h3 className="font-semibold text-white">Receive articles, tips, and offers from Oura</h3>
            <div className="flex py-4 items-center space-x-2">
              <input
                type="email"
                placeholder="Email address"
                className="flex-grow md:flex-grow-0 w-full md:w-auto px-2 md:px-4 py-2 rounded-md bg-gray-700 placeholder-gray-400 text-white focus:outline-none focus:ring-2 focus:ring-[#fc0829]"
              />
              <button className="px-3 md:px-6 py-[6px] bg-[#EE344E] text-white text-md md:text-lg rounded-md hover:bg-[#fc0829]">
                Subscribe
              </button>
            </div>

            <p className="text-white text-xs">We care about protecting your data.</p>
            <p className="text-white text-xs">Read more in our <a href="" className="underline">Privacy Policy.</a></p>
          </div>
        </div>
        <div className="mt-8 flex flex-col gap-2 text-center text-xs text-white md:text-left border-t border-[#5D5C5B] pt-4">
          {/* <div className="flex justify-center md:justify-start space-x-4 mt-4">
           <a href="/policy" className="">
             Terms & Conditions
           </a>
           <Link to='/policy' className="">
             Privacy Policy
           </Link>
           <a href="#" className="">
             Accessibility
           </a>
           <a href="#" className="">
             IP Notice
           </a>
           <a href="#" className="">
             Security Center
           </a>
         </div> */}
          <p className="mb-2 md:mb-0">
            © 2024 MedRinger. All Rights Reserved.
          </p>
          <p className="mb-2 md:mb-0">
            MedRinger are registered trademarks of MedRinger and cannot be used without explicit permission.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
