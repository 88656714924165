import React from 'react'
import { Link } from 'react-router-dom';
import image from '../images/product-image/move-video.jpg';
import image1 from '../images/product-image/move-03.jpg';
import Header from '../components/Header';
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import banner from '../images/orignal/webp/Hafiz-1.webp'
function Products() {
    const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: false, threshold: 1 });
  return  (
    <>
    <Header
     backgroundImage={banner}
     bannerHeading="Explore Our Ringer Collection"
     bannerText="Shop our stylish, comfortable medium ringer products, designed for everyday wear and lasting comfort."
    />
    <section className="container mx-auto px-6 py-10">
        <div className='text-center'>
    <motion.div
        ref={ref}
        className="w-[90%] md:w-[45%] flex flex-col gap-5 justify-center items-center mx-auto py-10"
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="md:text-7xl text-4xl text-center font-[400] bg-gradient-to-r from-[#8f0317] to-[#f52a46] bg-clip-text text-transparent">
        Our MedAlert
          <span className="italic md:text-6xl text-3xl"> Smart Devices</span>
        </h1>

        <p className="text-center text-[18px] font-[400] leading-[24px] text-[#5D5C5B] md:text-md">
        Discover our reliable MedAlert devices designed to provide peace of mind and safety. Choose the one that best suits your needs.
        </p>
      </motion.div>
      </div>
      <div className=" max-w-[1440px] w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 font-akkurat">
        {/* Product 1 */}
        <div className="bg-white shadow-lg rounded-lg md:p-6 p-0">
          <img src={image} alt="MedAlert Device 1" className="w-full h-[300px] object-cover rounded-md mb-4" />
          <div className='p-6'>
          <h3 className="text-2xl font-semibold mb-2">In Home</h3>
          <p className="text-gray-600 mb-4">
            A reliable, easy-to-use device that ensures your safety with real-time alerts and an emergency response system.
          </p>
          <ul className="text-gray-700 mb-4 list-disc list-inside space-y-2">
            <li><Link to='/category/cellular-home' className='hover:text-[#fc0829] text-xl font-[400]'>Cellular Device</Link></li>
            <li><Link to='/category/landline' className='hover:text-[#fc0829] text-xl font-[400]'>LandLine Device</Link></li>
          </ul>
          </div>
         
        </div>

        {/* Product 2 */}
        <div className="bg-white shadow-lg rounded-lg md:p-6 p-0">
          <img src={image1} alt="MedAlert Device 2" className="w-full h-[300px] object-cover rounded-md mb-4" />
          <div className='p-6'>
          <h3 className="text-2xl font-semibold mb-2">On The Go</h3>
          <p className="text-gray-600 mb-4">
            Advanced alerting device with enhanced connectivity and customizable alert options to meet your unique needs.
          </p>
          <ul className="text-gray-700 mb-4 list-disc list-inside space-y-2">
           <li><Link to='/category/pendant' className='hover:text-[#fc0829] text-xl font-[400]'>Pendent</Link></li>
           <li><Link to='/category/cellular-watch' className='hover:text-[#fc0829] text-xl font-[400]'>Sim</Link></li>
           <li><Link to='/category/sos-watch' className='hover:text-[#fc0829] text-xl font-[400]'>SOS</Link></li>
          </ul>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Products