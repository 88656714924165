
import Header from "../components/Header";
import About from "./Home/About";
import ImageSlider from "./Home/ImageSlider";
import MainSection from "./Home/MainSection";
import ResponsiveSection from "./Home/ResponsiveSection";
import SectionWithTransitions from "./Home/SectionWithTransitions";
import Tabs from "./Home/Tabs";
import TechSpecifications from "./Home/TechSpecifications";
import banner from '../images/orignal/webp/Hafiz-1.webp'


const Home = () => {
  return (
    <>
      <div>
        <Header
         backgroundImage={banner}
        //  bannerHeight="110vh"
         bannerHeading="Your Safety, Our Priority"
         bannerSpan="MedAlert Ringer"
         bannerText="Stay protected with MedAlert's latest range of smart medical alert devices, designed for safety, convenience, and peace of mind."
         bannerButton="Explore Our Devices"
         buttonLink='/products'
         />
        
     <MainSection/>
     <SectionWithTransitions/>
     <About/>
     <ImageSlider/>
     <Tabs/>
     <ResponsiveSection/>
     <TechSpecifications/>
      </div>
    </>
  );
};

export default Home;
