import React from 'react';
import Header from '../components/Header';
import banner from '../images/orignal/webp/Hafiz-1.webp';

const ReturnPolicy = () => {
  return (
    <>
      <Header
        bannerHeight='35vh'
        backgroundImage={banner}
      />
      <div className="bg-gray-50 min-h-screen py-10 flex items-center justify-center">
        <div className="bg-white max-w-4xl w-full rounded-lg shadow-md p-8">
          <h1 className="text-4xl font-bold text-center text-[#fc0829] mb-6">MedRinger Alert - Return Policy</h1>
          
          <p className="text-gray-500 text-center mb-10">
            Effective Date: November 5, 2024
          </p>

          {/* Section: Introduction */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Introduction</h2>
            <p className="text-gray-700 leading-relaxed">
              Thank you for choosing MedRinger Alert. We stand behind our products and offer a straightforward return policy for 
              your satisfaction. This policy explains the conditions and process for returning MedRinger Alert products.
            </p>
          </section>

          {/* Section: Return Conditions */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Return Conditions</h2>
            <p className="text-gray-700 leading-relaxed">
              Returns are accepted within 30 days of the original purchase date. Products must be in their original packaging, 
              unused, and in the same condition as received. We reserve the right to refuse returns that do not meet these 
              conditions.
            </p>
          </section>

          {/* Section: Refund Process */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Refund Process</h2>
            <p className="text-gray-700 leading-relaxed">
              Once we receive and inspect your returned item, we will notify you via email. Approved returns will be processed 
              within 7-10 business days, and a credit will automatically be applied to your original method of payment.
            </p>
          </section>

          {/* Section: Shipping Costs */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Shipping Costs</h2>
            <p className="text-gray-700 leading-relaxed">
              Shipping costs for returning items are the responsibility of the customer unless the return is due to a defect or 
              error on our part. We recommend using a trackable shipping service to ensure your return is safely delivered.
            </p>
          </section>

          {/* Section: Contact Information */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Contact Information</h2>
            <p className="text-gray-700 leading-relaxed">
              For any questions about our return policy or assistance with your return, please contact us. We are here to help 
              make the return process as smooth as possible.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              <strong>Email:</strong> support@medringer.com
            </p>
            <p className="text-gray-700 leading-relaxed">
              <strong>Phone:</strong> +1 (800) 555-0199
            </p>
          </section>

          {/* Footer */}
          <div className="text-center mt-12">
            <p className="text-gray-500 text-sm">
              &copy; 2024 MedRinger, Inc. All rights reserved. MedRinger and MedRinger Alert are trademarks of MedRinger, Inc.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnPolicy;
