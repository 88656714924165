// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import OuraRing from './pages/OuraRing';
// import Cart from './pages/Cart.jsx';
// import Cart from './pages/Cart';
// import Header from './components/Header';
import Footer from './components/Footer';
import { CartProvider } from "./context/AppContext";
import Checkout from './pages/Checkout';
import TermPolicy from './pages/TermPolicy';
import Products from './pages/Products';
import Contact from './pages/Contact';
import FreeAdvise from './pages/FreeAdvise';
import Pendant from './pages/category/Pendant';
import CellularHome from './pages/category/CellularHome';
import Landline from './pages/category/Landline';
import Cellular from './pages/category/Cellular';
import Sos from './pages/category/Sos';
import WhyRinger from './pages/WhyRinger';
import ThankYouPage from './pages/Thank-you';
import HowItWorks from './pages/HowItsWorks';
import Faqs from './pages/Faqs';
import ReturnPolicy from './pages/ReturnPolicy';

function App() {
  return (
    <CartProvider>
    <Router>
      {/* <Header/> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/oura-ring" element={<OuraRing />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path ="/policy" element={<TermPolicy/>} />
        <Route path ="/return-policy" element={<ReturnPolicy/>} />
        <Route path ="/products" element={<Products/>} />
        <Route path ="/why-ringer" element={<WhyRinger/>} />
        <Route path ="/thank-you" element={<ThankYouPage/>} />
        <Route path ="/how-it-work" element={<HowItWorks/>} />
        <Route path ="/faq's" element={<Faqs/>} />


        <Route path ="/category/ringer-watch" element={<Cellular/>} />
        <Route path ="/category/ringer-push-button" element={<Sos/>} />
        <Route path="/category/pendant" element={<Pendant />} />
        <Route path ="/category/cellular-home" element={<CellularHome/>} />
        <Route path ="/category/landline" element={<Landline/>} />
      

        <Route path ="/contact" element={<Contact/>} />
        <Route path ="/free-advise" element={<FreeAdvise/>} />
      </Routes>
      <Footer/>
    </Router>
    </CartProvider>
  );
}

export default App;
