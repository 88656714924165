import React, { useState } from 'react';
import Header from '../components/Header';
import banner from '../images/orignal/webp/Hafiz-1.webp';

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null); // To track which question is expanded

  const faqs = [
    { question: "What is MedAlert?", answer: "MedAlert is a medical alert system that connects you to emergency services instantly." },
    { question: "How much does it cost?", answer: "Our plans vary depending on the features you need. Contact us for more details." },
    { question: "Who should use MedAlert?", answer: "MedAlert is ideal for seniors, people with health conditions, and anyone who needs emergency support." },
    { question: "How does MedAlert detect emergencies?", answer: "MedAlert provides various options for emergency detection, including manual button alerts and automated fall detection for certain plans. If a fall or emergency is detected, alerts are immediately sent to your contacts." },
    { question: "Can MedAlert be used outside the home?", answer: "Yes, MedAlert offers portable devices that work both inside and outside the home, ensuring you're protected wherever you go." }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between expanded and collapsed
  };

  return (
    <>
      <Header
        // bannerHeight='60vh'
        backgroundImage={banner}
        bannerHeading="Frequently Asked Questions"
        bannerText="Find answers to common questions about our services and policies."
      />
      <div className="bg-white py-16 font-akkurat">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-[#fc0829] text-center mb-8">Frequently Asked Questions</h2>

          {/* Accordion Content */}
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleAccordion(index)}
                  className={`w-full text-left ${activeIndex === index ? 'bg-[#fc0829] text-white' : 'text-gray-800'} font-semibold flex justify-between py-4 px-6`}
                >
                  {faq.question}
                  <span className={`text-xl text-[#fc0829] ${activeIndex === index ? 'rotate-180 text-white' : ''} transition-transform duration-500`}>
                    &#9660;
                  </span>
                </button>
                {/* Adding smooth height transition */}
                <div
                  className={`overflow-hidden transition-all duration-500 ease-in-out ${activeIndex === index ? 'max-h-40' : 'max-h-0'}`}
                >
                  <p className="mt-2 text-gray-600 px-6 py-5">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
