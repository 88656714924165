import React from 'react';
import Header from '../components/Header';
import banner from '../images/orignal/webp/Hafiz-1.webp'
const TermPolicy = () => {
  return (
    <>
<Header
bannerHeight='35vh'
backgroundImage={banner}
/>
    <div className="bg-gray-50 min-h-screen py-10 flex items-center justify-center">
      <div className="bg-white max-w-4xl w-full rounded-lg shadow-md p-8">
        <h1 className="text-4xl font-bold text-center text-[#fc0829] mb-6">MedRinger Alert - Terms & Policy</h1>
        
        <p className="text-gray-500 text-center mb-10">
          Effective Date: November 5, 2024
        </p>

        {/* Section: Introduction */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Introduction</h2>
          <p className="text-gray-700 leading-relaxed">
            Welcome to the MedRinger Alert Terms and Policy page. This document provides information on how MedRinger Alert works, 
            including your rights and responsibilities as a user. By accessing MedRinger Alert, you agree to the terms described here.
          </p>
        </section>

        {/* Section: Usage Terms */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Usage Terms</h2>
          <p className="text-gray-700 leading-relaxed">
            MedRinger and MedRinger Alert are trademarks of MedRinger, Inc., and may not be used without permission. MedRinger Alert 
            provides real-time notifications for medical and safety information. The information is intended to supplement, not 
            replace, professional medical advice. Unauthorized use or redistribution of alerts is prohibited.
          </p>
        </section>

        {/* Section: Privacy Policy */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Privacy Policy</h2>
          <p className="text-gray-700 leading-relaxed">
            MedRinger respects your privacy. We collect and store only the essential information required to provide accurate and 
            timely alerts. Personal information will not be shared with third parties unless required by law or consented to by you.
          </p>
        </section>

        {/* Section: Data Collection and Usage */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Data Collection and Usage</h2>
          <p className="text-gray-700 leading-relaxed">
            We collect data to improve the functionality and reliability of MedRinger Alert. This may include usage data, alert 
            response times, and general location information to provide relevant alerts. All data is handled in compliance with 
            applicable privacy laws.
          </p>
        </section>

        {/* Section: Contact Information */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#fc0829] mb-3">Contact Information</h2>
          <p className="text-gray-700 leading-relaxed">
            If you have questions or need further clarification about our terms or policies, please reach out. We are here to 
            assist you with any inquiries related to MedRinger Alert.
          </p>
          <p className="text-gray-700 leading-relaxed mt-4">
            <strong>Email:</strong> support@medringer.com
          </p>
          <p className="text-gray-700 leading-relaxed">
            <strong>Phone:</strong> +1 (800) 555-0199
          </p>
        </section>

        {/* Footer */}
        <div className="text-center mt-12">
          <p className="text-gray-500 text-sm">
            &copy; 2024 MedRinger, Inc. All rights reserved. MedRinger and MedRinger Alert are trademarks of MedRinger, Inc.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default TermPolicy;
