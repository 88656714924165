import React from 'react';
import image from '../images/1.jpg'
import Header from '../components/Header';
import banner from '../images/orignal/webp/Hafiz-1.webp'
import image1 from '../images/about/firewall-antivirus-alert-protection-security-caution-concept.webp'
import image2 from '../images/about/high-angle-woman-holding-laptop.webp'
import image3 from '../images/about/1_wire_30_09_2021_02.webp'


const WhyRinger = () => {
    return (
        <>
            <Header
                backgroundImage={banner}
                bannerHeading="About Us"
                bannerText="The Trusted Choice in Emergency Alerts."
            />
            <div className="bg-gray-50 text-gray-700 font-akkurat">
                <section className=" max-w-[1440px] w-full mx-auto py-8 px-4 md:py-16 md:px-16 text-center md:text-left">
                    <div className="flex flex-col gap-5 md:flex-row md:justify-between items-center">
                        {/* Text Section */}
                        <div className="md:w-1/2 space-y-4">
                            <h1 className="text-3xl md:text-4xl font-[400] text-red-600 ">
                                Ensuring Safety, One Alert at a Time
                            </h1>
                            <p className="text-lg md:text-xl text-gray-600">
                                At Med Alert Ringer, our mission is to provide reliable, life-saving alerts for those who need them most. Our technology is designed to deliver quick, clear, and dependable notifications to ensure that help is always within reach.
                            </p>
                            <p className="text-lg md:text-xl text-gray-600">
                                Our vision is a world where everyone, from the elderly to those with medical conditions, can live with confidence, knowing that support is just a ring away.
                            </p>
                        </div>

                        {/* Image Section */}
                        <div className="md:w-1/2 mt-8 md:mt-0">
                            <img src={image} alt="Med Alert Ringer" className="w-full h-auto rounded-lg shadow-lg" />
                        </div>
                    </div>
                </section>

                {/* Section 1: How It Works */}
                <section className="bg-gray-100 py-16 px-4 md:px-16">
                    <h2 className="text-2xl md:text-3xl font-[400] text-red-600 text-center">Simple. Reliable. Life-Saving.</h2>

                    <div className=" max-w-[1440px] w-full mx-auto mt-8 flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0 md:space-x-4">
                        {/* Card 1 */}
                        <div className="flex flex-col gap-3 items-center text-center max-w-[340px] bg-white rounded-lg shadow-lg p-6 border border-gray-200">
                            <img src={image1} alt="Instant Alerts" className="w-28 h-28 rounded-full mb-4" />
                            <h3 className="font-[400] text-2xl text-red-600">Instant Alerts</h3>
                            <p className="text-gray-600">Real-time notifications sent to caregivers and emergency personnel.</p>
                        </div>

                        {/* Card 2 */}
                        <div className="flex flex-col gap-3 items-center text-center max-w-[340px] bg-white rounded-lg shadow-lg p-6 border border-gray-200">
                            <img src={image2} alt="Advanced Location Tracking" className="w-28 h-28 rounded-full mb-4" />
                            <h3 className="font-[400] text-2xl text-red-600">Advanced Location Tracking</h3>
                            <p className="text-gray-600">Pinpoint user location to ensure fast assistance.</p>
                        </div>

                        {/* Card 3 */}
                        <div className="flex flex-col gap-3 items-center text-center max-w-[340px] bg-white rounded-xl shadow-lg p-6 border border-gray-200">
                            <img src={image3} alt="Battery and Durability" className="w-28 h-28 rounded-full mb-4" />
                            <h3 className="font-[400] text-2xl text-red-600">Battery and Durability</h3>
                            <p className="text-gray-600">Long-lasting battery with weather-resistant design.</p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Why Choose Us */}
                {/* <section className="bg-white py-16 px-8 md:px-16">
                <h2 className="text-2xl md:text-3xl font-semibold text-red-600 text-center">The Trusted Choice in Emergency Alerts</h2>
                <p className="mt-4 text-lg md:text-xl text-gray-600 text-center max-w-3xl mx-auto">
                    Med Alert Ringer is trusted by families, healthcare providers, and seniors alike. Our commitment to innovation and safety has made us a leader in the medical alert industry.
                </p>
                <div className="mt-8 flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0 md:space-x-4">
                    <div className="flex flex-col items-center text-center max-w-xs">
                        <h3 className="font-bold text-lg text-red-600">24/7 Monitoring</h3>
                        <p className="text-gray-600">Round-the-clock response for every alert.</p>
                    </div>
                    <div className="flex flex-col items-center text-center max-w-xs">
                        <h3 className="font-bold text-lg text-red-600">User-Friendly Interface</h3>
                        <p className="text-gray-600">Designed for simplicity, accessible to all ages.</p>
                    </div>
                    <div className="flex flex-col items-center text-center max-w-xs">
                        <h3 className="font-bold text-lg text-red-600">Affordable Pricing</h3>
                        <p className="text-gray-600">Quality care at a price you can afford.</p>
                    </div>
                </div>
            </section> */}
            </div>
        </>
    );
};

export default WhyRinger;
