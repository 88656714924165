import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../context/AppContext";
import logo from '../images/logo/PNG.png'

export default function OuraRing() {
  const { addToCart } = useCart(); // Get only addToCart from the context
  const [selectedProduct, setSelectedProduct] = useState({
    id: 1,
    name: "Silver Ring",
    price: 349,
    image: "./Shop Oura Ring 4_ Silver/or4-silver.webp",
    finish: "Silver",
    images: [
      "./Shop Oura Ring 4_ Silver/or4-silver.webp",
      "./Shop Oura Ring 4_ Silver/or4-silver-angle.webp",
      "./Shop Oura Ring 4_ Silver/or4-silver-lifestyle-v2.webp",
    ],
  });
  const [selectedImage, setSelectedImage] = useState(selectedProduct.images[0]);

  const products = [
    {
      id: 1,
      name: "Silver Ring",
      price: 349,
      image: "./Shop Oura Ring 4_ Silver/or4-silver.webp",
      finish: "Silver",
      images: [
        "./Shop Oura Ring 4_ Silver/or4-silver.webp",
        "./Shop Oura Ring 4_ Silver/or4-silver-angle.webp",
        "./Shop Oura Ring 4_ Silver/or4-silver-lifestyle-v2.webp",
      ],
    },
    {
      id: 2,
      name: "Black Ring",
      price: 349,
      image: "./Shop Oura Ring 4_ Silver/or4-black.webp",
      finish: "Black",
      images: [
        "./Shop Oura Ring 4_ Silver/or4-black.webp",
        "./Shop Oura Ring 4_ Silver/or4-black-angle.webp",
        "./Shop Oura Ring 4_ Silver/or4-black-lifestyle-v2.webp",
      ],
    },
    {
      id: 3,
      name: "Brushed Silver Ring",
      price: 399,
      image: "./Shop Oura Ring 4_ Silver/or4-brushed.webp",
      finish: "Brushed Silver",
      images: [
        "./Shop Oura Ring 4_ Silver/or4-brushed.webp",
        "./Shop Oura Ring 4_ Silver/or4-brushed-silver-angle.webp",
        "./Shop Oura Ring 4_ Silver/or4-brushed-silver-lifestyle-v2.webp",
      ],
    },
  ];

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setSelectedImage(product.images[0]);
  };

  return (
    <>
      {/* Main content */}
      <main className="mx-auto max-w-[1800px] w-full px-4 sm:px-6 lg:px-0">
        <div className="grid gap-8 lg:grid-cols-12">
          <div className="lg:col-span-8 w-full relative">
            <header className="border-b px-5">
              <div className="absolute top-10 w-[93%] flex items-center justify-between ">
              <Link to='./' className="w-[150px] h-[50px] text-3xl font-[400] leading-24">
              <img src={logo} alt="" className="w-full h-full" />
            </Link>
                {/* <CartIcon /> Use CartIcon component */}
              </div>
            </header>
            <div className="aspect-square h-[300px] md:h-screen w-[100%] overflow-hidden rounded-lg border bg-white"> {/* Adjusted height */}
              <img
                src={selectedImage}
                alt="Product"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="absolute bottom-4 md:bottom-16 left-1/2 transform -translate-x-1/2 flex justify-center gap-4">
              {selectedProduct.images.map((thumb, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedImage(thumb)}
                  className="overflow-hidden rounded-lg border bg-white hover:border-[#EE344E] px-1 py-2"
                >
                  <img
                    src={thumb}
                    alt={`Thumbnail ${index + 1}`}
                    className="h-12 w-16 md:h-16 md:w-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>

          <div className="lg:col-span-4 w-full space-y-6">
            <div className="w-full space-y-6 overflow-auto h-[400px]">
              <h2 className="text-xl font-semibold">
                Select the finish of your Ring
              </h2>
              <div className="mt-4 space-y-2">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className={`cursor-pointer p-4 border rounded-lg ${
                      selectedProduct.id === product.id
                        ? "border-[#EE344E]"
                        : ""
                    }`}
                    onClick={() => handleProductClick(product)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <img
                          src={product.image}
                          alt={product.name}
                          className="h-16 w-16 rounded-md"
                        />
                        <span className="font-medium">{product.finish}</span>
                      </div>
                      <span className="font-semibold">${product.price}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center justify-between border-t pt-6">
              <div className="text-2xl font-bold">
                ${selectedProduct.price} USD
              </div>
              <Link to='/checkout'
                onClick={() => addToCart(selectedProduct)}
                className="px-6 py-3 text-xl bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829] mb-5 md:mb-0"
              >
                Add to Cart
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
