'use client';

import * as React from 'react';
import Vedio from "../../images/vedio/Rotating Watch 3d.mp4";
import Image from '../../images/long-term-health-v2-en.webp';
import { DiAndroid } from "react-icons/di";
import { FaApple } from "react-icons/fa";


export default function About() {
  const [isSection1Visible, setIsSection1Visible] = React.useState(false);
  const [isSection2Visible, setIsSection2Visible] = React.useState(false);
  const section1Ref = React.useRef(null);
  const section2Ref = React.useRef(null);

  React.useEffect(() => {
    const observer1 = new IntersectionObserver(
      ([entry]) => setIsSection1Visible(entry.isIntersecting),
      { threshold: 0.5 }
    );
    const observer2 = new IntersectionObserver(
      ([entry]) => setIsSection2Visible(entry.isIntersecting),
      { threshold: 0.18 }
    );

    if (section1Ref.current) observer1.observe(section1Ref.current);
    if (section2Ref.current) observer2.observe(section2Ref.current);

    return () => {
      observer1.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <>
      <div className="relative max-w-[1440px] w-full mx-auto">
        <div className="relative desktop:block hidden">
          {/* Section 1 - Battery Life */}
          <section
            ref={section1Ref}
            className="sticky top-0 z-10 flex min-h-[80vh] items-center justify-between bg-[#F5F5F7] px-6 py-6 lg:px-16"
          >
            <div
              className={`max-w-md space-y-4 transition-all duration-500 ${
                isSection2Visible
                  ? 'opacity-20 blur-md translate-y-5'
                  : isSection1Visible
                  ? 'opacity-100 blur-none -translate-y-10'
                  : 'opacity-0 blur-md translate-y-5'
              }`}
            >
              <h2 className="text-[56px] font-[400] leading-[61.6px] font-akkurat tracking-tight text-gray-700">
                Up to 8 days of battery life
              </h2>
              <p className="text-[24px] font-[400] leading-[32px] font-akkurat text-gray-700 pr-24">
              Based on your Oura Ring size and your lifestyle, the Oura Ring 4 battery can last over a week — so you can go days without even thinking about charging.*
              </p>
            </div>
            <div className="relative hidden aspect-square w-full desktop:h-[550px] desktop:w-[600px] bigdesktop:h-[700px] bigdesktop:w-[800px] lg:block">
              <video src={Vedio} autoPlay loop muted className="absolute inset-0 h-[93%] w-[90%] mx-auto object-cover rounded-xl" />
            </div>
          </section>

          {/* Section 2 - Health */}
          <section
            ref={section2Ref}
            className={`relative z-20 desktop:min-h-[90vh] bigdesktop:min-h-[50vh] bg-[#F5F5F7] backdrop-blur-sm transition-opacity duration-300 ${
              isSection2Visible ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div
              className={`flex min-h-[75vh] bigdesktop:min-h-[60vh] desktop:pb-20 bigdesktop:pb-40 bigdesktop:pt-0 items-center justify-between px-6 lg:px-16 transition-all duration-300 ${
                isSection2Visible ? 'opacity-100 blur-none translate-y-0' : 'opacity-0 blur-sm translate-y-5'
              }`}
            >
              <div
                className={`max-w-md space-y-4 transition-all duration-500 ${
                  isSection2Visible ? 'opacity-100 blur-none -translate-y-10' : 'opacity-20 blur-md translate-y-5'
                }`}
              >
                <h2 className="text-[56px] font-[400] leading-[61.6px] font-akkurat tracking-tight text-gray-700 lg:text-6xl">
                  Live healthier, longer
                </h2>
                <p className="text-[24px] font-[400] leading-[32px] font-akkurat text-gray-700 pr-24">
                  Oura Membership and Oura Ring work hand-in-hand, empowering you to prioritize your long-term health goals and build healthy habits that will last a lifetime.
                </p>
                {/* <div className="flex items-center gap-4 pt-10">
                  <DiAndroid className="w-8 h-8" />
                  <FaApple className="w-8 h-8" />
                  <span className="text-[20px] font-[400] leading-[32px] font-akkurat text-gray-600">iOS & Android Compatible</span>
                </div> */}
              </div>
              <div className="relative hidden aspect-square w-full desktop:h-[550px] desktop:w-[600px] bigdesktop:h-[700px] bigdesktop:w-[800px] lg:block">
                <img src={Image} alt="Oura App Interface" className="absolute inset-0 h-[93%] w-[90%] mx-auto object-cover rounded-xl" />
              </div>
            </div>
          </section>
        </div>

        {/* Mobile Layout - Grid View */}
        <div className="desktop:hidden grid grid-cols-1 gap-10 p-4">
          {/* Section 1 Content */}
          <div className="flex flex-col items-start justify-center space-y-4">
            <h2 className="text-[32px] font-[400] leading-[35.2px] font-akkurat tracking-tight text-gray-700">Up to 8 days of battery life</h2>
            <p className="text-[20px] font-[400] leading-[27.5px] font-akkurat text-gray-700">Based on your Oura Ring size and your lifestyle, the Oura Ring 4 battery can last over a week — so you can go days without even thinking about charging.*</p>
          </div>
          <div className="w-full aspect-square">
            <video src={Vedio} autoPlay loop muted className="w-full h-full object-cover rounded-lg" />
          </div>

          {/* Section 2 Content */}
          <div className="flex flex-col items-start justify-center space-y-4">
            <h2 className="text-[32px] font-[400] leading-[35.2px] font-akkurat tracking-tight text-gray-700">Live healthier, longer</h2>
            <p className="text-[20px] font-[400] leading-[27.5px] font-akkurat text-gray-700">
            Oura Membership and Oura Ring work hand-in-hand, empowering you to prioritize your long-term health goals and build healthy habits that will last a lifetime. Personalized insights from Oura help you understand what your body needs, for today and for your future.
            </p>
            {/* <div className="flex items-center gap-2">
              <DiAndroid className="w-6 h-6" />
              <FaApple className="w-6 h-6" />
              <span className="text-sm text-gray-600">iOS & Android Compatible</span>
            </div> */}
          </div>
          <div className="w-full aspect-square">
            <img src={Image} alt="Oura App Interface" className="w-full h-full object-cover rounded-lg" />
          </div>
        </div>
      </div>
    </>
  );
}
